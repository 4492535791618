import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from '../../services/admin-service.service';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import { NgProgress } from 'ngx-progressbar';
import * as jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
admins: any = [];
  constructor(private adminService: AdminServiceService,private router: Router,
    private route: ActivatedRoute, private loader: NgProgress) { }

  ngOnInit() {
    this.loader.start();
    this.adminService.getUser();
    this.adminService.getAdmins();
    this.adminService.getAdminUpdateListen()
    .subscribe(data => {
      console.log(data)
      this.admins = data;
      this.loader.done();
    })
  }

  toRegisterAdmin() {
  this.router.navigate(['/admin/register'])
  }

  blockAdmin(id) {
    this.adminService.blockAdmin(id)
    .subscribe(data => {
      console.log(data);
      this.adminService.getAdmins();
    })
  }

  unblockAdmin(id) {
    this.adminService.unblockAdmin(id)
    .subscribe(data => {
      console.log(data);
      this.adminService.getAdmins();
    })
  }

  toEdit(id) {
    this.router.navigate([`/edit/${id}`])
  }

  
  toAdmins(e) {
    e.preventDefault();
    this.router.navigate(['/admins'])
  }
  toProperties(e) {
    e.preventDefault();
    this.router.navigate(['/properties'])
  }
  toSellers(e) {
    e.preventDefault();
    this.router.navigate(['/sellers'])
  }
  toBuyers(e) {
    e.preventDefault();
    this.router.navigate(['/buyers'])
  }
  toBlogs(e) {
    e.preventDefault();
    this.router.navigate(['/blogs'])
  }
}
