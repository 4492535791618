import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from '../../services/admin-service.service';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import { NgProgress } from 'ngx-progressbar'
@Component({
  selector: 'app-buyers',
  templateUrl: './buyers.component.html',
  styleUrls: ['./buyers.component.scss']
})
export class BuyersComponent implements OnInit {

  constructor(private adminService: AdminServiceService,private router: Router,
    private route: ActivatedRoute, private loader: NgProgress) { }

  ngOnInit() {
  }

  toAdmins(e) {
    e.preventDefault();
    this.router.navigate(['/admins'])
  }
  toProperties(e) {
    e.preventDefault();
    this.router.navigate(['/properties'])
  }
  toSellers(e) {
    e.preventDefault();
    this.router.navigate(['/sellers'])
  }
  toBuyers(e) {
    e.preventDefault();
    this.router.navigate(['/buyers'])
  }
  toBlogs(e) {
    e.preventDefault();
    this.router.navigate(['/blogs'])
  }
}
