import {Component, OnInit} from '@angular/core';
import {AdminServiceService} from '../../services/admin-service.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import { NgProgress } from 'ngx-progressbar';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {FormGroup, FormControl, Validators} from "@angular/forms";
import * as jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
  public Editor = ClassicEditor;
  decodedToken: any;
  id: any;
  noImage: any = true;
  imageUrl = new Array<string>();
  imageUrlFile: Array<File> = [];
  textEditor: FormGroup;
  editorConfig: AngularEditorConfig;
  public options: Object = {
    placeHolderText: 'Enter the content of Blog !'
  };
  model: any = {};

  constructor(private adminService: AdminServiceService, private router: Router,
              private route: ActivatedRoute, private loader: NgProgress) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    if(this.id) {
      this.loader.start();
      this.adminService.singleBlog(this.id)
      .subscribe(data => {
        this.noImage = false;
        console.log(data);
        this.imageUrl = [data.data.image];
        console.log(this.imageUrl, 'this is image')
        this.textEditor.patchValue({
          blogText: data.data.text,
          blogTitle: data.data.title,
          blogSlug: data.data.slug,
          blogAlt: data.data.alt ? data.data.alt : ''
        });
        this.loader.done();
      })
    }

    this.decodedToken = jwt_decode(localStorage.getItem('tokenCheck'));
    console.log(this.decodedToken, 'blog token')
    this.textEditor = new FormGroup({
      blogText: new FormControl(null),
      blogTitle: new FormControl(null),
      blogSlug: new FormControl(null),
      blogAlt: new FormControl(null)
    });

    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '300px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '400',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      toolbarPosition: 'top',
    };
  }


  onSubmit() {
    console.log(this.textEditor.value)
  }


  fileChangeEventEpc(fileInput: any) {
    this.noImage = false;
    this.imageUrl = [];
    let files = fileInput.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {

          this.imageUrl.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
    this.imageUrlFile = <Array<File>>fileInput.target.files;
  }

  submitBlog() {
    this.loader.start();
    console.log(this.textEditor.value);
    console.log(this.imageUrlFile);
    const imageFiles: Array<File> = this.imageUrlFile;
    const formData = new FormData();
    formData.append("title", this.textEditor.value.blogTitle);
    formData.append('text', this.textEditor.value.blogText);
    if(imageFiles.length > 0) {
      formData.append('image', imageFiles[0]);
    }
   
    if(this.textEditor.value.blogSlug) {
      formData.append('slug', this.textEditor.value.blogSlug);
    }
   
    formData.append('alt', this.textEditor.value.blogAlt);
    formData.append('creator', this.decodedToken.user._id);
    if(this.id) {
      formData.append('edit', 'true');
      formData.append('id', this.id)
    }
    this.adminService.createBlog(formData)
      .subscribe(data => {
        if (data.success === 1) {
          this.loader.done();
          this.router.navigate(['/allblogs']);
          
        }
      })

  }

  preventSpace(e) {
    if(e.keyCode === 32) {
      e.preventDefault();
      return false;
    }
    
  }

  toAdmins(e) {
    e.preventDefault();
    this.router.navigate(['/admins'])
  }

  toProperties(e) {
    e.preventDefault();
    this.router.navigate(['/properties'])
  }

  toSellers(e) {
    e.preventDefault();
    this.router.navigate(['/sellers'])
  }

  toBuyers(e) {
    e.preventDefault();
    this.router.navigate(['/buyers'])
  }

  toBlogs(e) {
    e.preventDefault();
    this.router.navigate(['/blogs'])
  }
}
