import {Component, OnInit} from '@angular/core';
import {AdminServiceService} from '../../services/admin-service.service';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import {NgProgress} from 'ngx-progressbar';
import {FormGroup, FormBuilder, Validators, FormControl} from "@angular/forms";

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss']
})
export class PropertyDetailComponent implements OnInit {
  property: any;
  id: any;

  constructor(
    private adminService: AdminServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private loader: NgProgress,
  ) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.loader.start();
    this.adminService.getPropertyDetail(this.id)
      .subscribe(data => {
        console.log(data);
        this.property = data.data;
        this.loader.done();
      })
  }

}
