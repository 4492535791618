import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from '../../services/admin-service.service';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import { NgProgress } from 'ngx-progressbar';
import { FormGroup, FormControl, Validators } from "@angular/forms";
@Component({
  selector: 'app-all-blogs',
  templateUrl: './all-blogs.component.html',
  styleUrls: ['./all-blogs.component.scss']
})
export class AllBlogsComponent implements OnInit {
blogs: any = [];
url: any = 'https://www.propertiser.co.uk/blogs/';
  constructor(private adminService: AdminServiceService,private router: Router,
    private route: ActivatedRoute, private loader: NgProgress) { }

  ngOnInit() {
    this.loader.start();
    this.adminService.getBlogs();
    this.adminService.getUpdatedBlogs()
    .subscribe(data => {
      console.log(data);
      this.blogs = data;
      this.loader.done();
    })

  }

  addBlog() {
    this.router.navigate(['/blogs'])
  }

  deleteBlog(id) {
    this.loader.start();
    this.adminService.deleteBlog(id)
    .subscribe(data => {
      this.adminService.getBlogs();
      this.loader.done();
    })
  }

  editBlog(id) {
    this.router.navigate([`/blog/${id}`]);
  }

}
