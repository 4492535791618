import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from '../../services/admin-service.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
login: FormGroup;
  constructor(private adminService: AdminServiceService,private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.login = new FormGroup({
      email: new FormControl(null),
      password: new FormControl(null)
    });
    
  }
loginForm() {
  this.adminService.login(this.login.value)
    .subscribe(data => {
      console.log(data);
      if(data.success === 1) {
        localStorage.setItem('tokenCheck', data.token);
        localStorage.setItem('email', data.user.email);
        localStorage.setItem('name', data.user.name);
        this.router.navigate(['/admins'])
      }
      

    })
}
}
