import { Component, OnInit } from '@angular/core';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import { AdminServiceService } from '../../services/admin-service.service';

import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  decodedToken: any = {
    admins: null,
    properties: null,
    buyers: null,
    sellers: null,
    blogs: null
  };
  decodedToken_1: any;
tokenCheck: any = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminServiceService
  ) { }

  ngOnInit() {
  
    this.adminService.getUser();
    this.adminService.getUserUpdated()
    .subscribe(data => {
      console.log(data, 'this is the data');
      this.decodedToken_1 = data;
      this.decodedToken = this.decodedToken_1.data;
      console.log(this.decodedToken)
    })
    
  }

  toAdmins(e) {
    e.preventDefault();
    this.router.navigate(['/admins'])
  }
  toProperties(e) {
    e.preventDefault();
    this.router.navigate(['/properties'])
  }
  toSellers(e) {
    e.preventDefault();
    this.router.navigate(['/sellers'])
  }
  toBuyers(e) {
    e.preventDefault();
    this.router.navigate(['/buyers'])
  }
  toBlogs(e) {
    e.preventDefault();
    this.router.navigate(['/allblogs'])
  }
  prevent(e) {
    e.preventDefault();
  }
}
