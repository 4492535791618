import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from '../../services/admin-service.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgProgress } from 'ngx-progressbar';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  loaded: boolean = false;
  editAdmin: FormGroup;
  admin: any = {};
  id: any;
  constructor(private adminService: AdminServiceService,private router: Router,
    private route: ActivatedRoute, private loader: NgProgress) { }

  ngOnInit() {
    this.loader.start();
    this.adminService.getUser();
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    this.editAdmin = new FormGroup({
      email: new FormControl(null),
      name: new FormControl(null),
      blogs: new FormControl(null),
      buyers: new FormControl(null),
      sellers: new FormControl(null),
      properties: new FormControl(null),
      admins: new FormControl(null)
    })
    this.adminService.getAdmin(this.id)
    .subscribe(data => {
      console.log(data)
      this.admin = data.data;
      this.loaded = true;
      this.editAdmin.patchValue({
        name: this.admin.name,
        email: this.admin.email,
        admins: this.admin.admins,
        buyers: this.admin.buyers,
        sellers: this.admin.sellers,
        blogs: this.admin.blogs,
        properties: this.admin.properties
      });
      this.loader.done();
    })
  }

  editAdminData() {
    console.log(this.editAdmin.value)
    this.editAdmin.value.id = this.id;
    this.adminService.editAdmin(this.editAdmin.value)
    .subscribe(data => {
      console.log(data);
      if(data.success === 1) {
        this.router.navigate(['/admins']);
      }
    })
  }
  toAdmins(e) {
    e.preventDefault();
    this.router.navigate(['/admins'])
  }
  toProperties(e) {
    e.preventDefault();
    this.router.navigate(['/properties'])
  }
  toSellers(e) {
    e.preventDefault();
    this.router.navigate(['/sellers'])
  }
  toBuyers(e) {
    e.preventDefault();
    this.router.navigate(['/buyers'])
  }
  toBlogs(e) {
    e.preventDefault();
    this.router.navigate(['/blogs'])
  }
}
