import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from '../../services/admin-service.service';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import { NgProgress } from 'ngx-progressbar';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit {
  properties: any = [];
  pagination: any = [];
  page: any = 1;
  pageCount: any = [];
  entryLength: any = 10;
  form: FormGroup;
  searchResult: boolean = false;
  // propertyLength: any;
  constructor(
    // private flashMessage: FlashMessagesService,
    private adminService: AdminServiceService,
    private router: Router,
    private route: ActivatedRoute, 
    private loader: NgProgress,
    
    ) { }

  ngOnInit() {
    this.loader.start();
    this.form = new FormGroup({
      property: new FormControl(null)
    })
    this.adminService.getPorperty();
    this.adminService.getUpdatedProperty()
    .subscribe(data => {
      this.pageCount = []
      console.log(data)
      this.properties = data;
      this.pagination = this.properties.slice((this.page - 1) * 10, ((this.page - 1) * 10) + 10);
      // this.pageCount = this.properties.length / this.entryLength;
      for(let i = 0; i< this.properties.length / this.entryLength; i++) {
        this.pageCount.push(i + 1)
      }
      this.loader.done();
      // this.propertyLength = this.properties.length;
    })
  }


  active(id) {
    this.adminService.makeActive(id)
    .subscribe(data => {
      if(data.success === 1) {
        this.adminService.getPorperty();
      }
    })
  }

  inactive(id) {
    this.adminService.makeInactive(id)
    .subscribe(data => {
      if(data.success === 1) {
        this.adminService.getPorperty();
      }
    })
  }

  changePage(count) {
    this.page = count;
    // if(this.page === 1) {
    //   this.pagination = this.properties.slice((this.page - 1), (this.page * this.entryLength));
    // }
    // else {
    //   this.pagination = this.properties.slice((this.page), (this.page * this.entryLength));
    // }
    this.pagination = this.properties.slice( (this.page - 1) * 10, ((this.page - 1) * 10) + 10);


    console.log(this.pagination)
  }


  changeSearchPage(count) {
    this.page = count;
    if(this.page === 1) {
      this.pagination = this.pagination.slice((this.page - 1), (this.page * this.entryLength));
    }
    else {
      this.pagination = this.pagination.slice((this.page), (this.page * this.entryLength));
    }

    console.log(this.pagination)
  }

  searchProperty() {
    const word = this.form.value.property.toUpperCase();

    if(word === '') {
      this.searchResult = false;
    }
    else {
      this.searchResult = true;
    }

    console.log(word)
    let array = [];
    for(const property of this.properties) {
      const name = `${property.bedroom} bed ${property.category}`;
      const address = `${property.streetName},${property.city},${property.country}`;
      if (address.toUpperCase().indexOf(word) > -1 || name.toUpperCase().indexOf(word) > -1 || property.sellerName.toUpperCase().indexOf(word) > -1 || property.dimension.toUpperCase().indexOf(word) > -1) {
        array.push(property);
       }
    }
    this.pagination = array;

  }

  uploadZoopla(id) {
    this.loader.start();
  this.adminService.zooplaUpload(id)
    .subscribe(data => {
      console.log(data);
      this.adminService.getPorperty();
      this.loader.done();
    })
  }

  toMail(id) {
    this.router.navigate([`/reject/${id}`])
  }

  toDetail(id) {
    this.router.navigate([`/property/${id}`])
  }
}
