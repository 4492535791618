import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from '../../services/admin-service.service';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import { NgProgress } from 'ngx-progressbar';
@Component({
  selector: 'app-sellers',
  templateUrl: './sellers.component.html',
  styleUrls: ['./sellers.component.scss']
})
export class SellersComponent implements OnInit {
sellers: any = [];
  constructor(private adminService: AdminServiceService,private router: Router,
    private route: ActivatedRoute, private loader: NgProgress) { }

  ngOnInit() {
    this.loader.start();
    this.adminService.getSellers();
    this.adminService.getSellersUpdated()
    .subscribe(data => {
      console.log(data)
      this.sellers = data;
      this.loader.done();
    })
  }

  toAdmins(e) {
    e.preventDefault();
    this.router.navigate(['/admins'])
  }
  toProperties(e) {
    e.preventDefault();
    this.router.navigate(['/properties'])
  }
  toSellers(e) {
    e.preventDefault();
    this.router.navigate(['/sellers'])
  }
  toBuyers(e) {
    e.preventDefault();
    this.router.navigate(['/buyers'])
  }
  toBlogs(e) {
    e.preventDefault();
    this.router.navigate(['/blogs'])
  }

  active(id) {
    this.adminService.makeSellerActive(id)
    .subscribe(data => {
      console.log(data);
      if(data.success === 1) {
        this.adminService.getSellers();
      }
    })
  }

  inactive(id) {
    this.adminService.makeSellerInactive(id)
    .subscribe(data => {
      console.log(data);
      if(data.success === 1) {
        this.adminService.getSellers();
        this.adminService.blockSellerMail(data.data.email);
      }
    })
  }
}
