import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AdminServiceService } from '../../services/admin-service.service';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  register: FormGroup;
  constructor(private adminService: AdminServiceService,private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.register = new FormGroup({
      name: new FormControl(null),
      email: new FormControl(null),
      password: new FormControl(null),
      admins: new FormControl(null),
      sellers: new FormControl(null),
      properties: new FormControl(null),
      buyers: new FormControl(null),
      blogs: new FormControl(null)
    })
  }


  reigsterAdmin() {
    console.log(this.register.value)
    this.adminService.registerAdmin(this.register.value)
    .subscribe(data => {
      console.log(data)
      if(data.success === 1) {
        this.router.navigate(['/admins'])
      }
    })
  }

  toAdmins(e) {
    e.preventDefault();
    this.router.navigate(['/admins'])
  }
  toProperties(e) {
    e.preventDefault();
    this.router.navigate(['/properties'])
  }
  toSellers(e) {
    e.preventDefault();
    this.router.navigate(['/sellers'])
  }
  toBuyers(e) {
    e.preventDefault();
    this.router.navigate(['/buyers'])
  }
  toBlogs(e) {
    e.preventDefault();
    this.router.navigate(['/blogs'])
  }
}
