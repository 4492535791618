import {Component, OnInit} from '@angular/core';
import {AdminServiceService} from '../../services/admin-service.service';
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {NgProgress} from 'ngx-progressbar';

import {
  RouterModule,
  Router,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

@Component({
  selector: 'app-reject',
  templateUrl: './reject.component.html',
  styleUrls: ['./reject.component.scss']
})
export class RejectComponent implements OnInit {
  sendMail: FormGroup;
  id: any;
  userId: any;

  constructor(
    private adminService: AdminServiceService, private router: Router,
    private route: ActivatedRoute, private loader: NgProgress
  ) {
  }

  ngOnInit() {
    this.loader.start();
    this.id = this.route.snapshot.params['id'];
    this.adminService.singleProperty(this.id)
      .subscribe(data => {

        this.adminService.singleUser(data.data.userid)
          .subscribe(data => {
            this.sendMail.patchValue({
              to: data.data.email
            })
          })

        this.loader.done();
      });
    this.sendMail = new FormGroup({
      to: new FormControl(null),
      reason: new FormControl(null)
    })
  }

  sendMails() {
    this.loader.start();
    this.sendMail.value.propertyId = this.id;
    this.adminService.sendMail(this.sendMail.value)
      .subscribe(data => {
        console.log(data);
        if (data.success === 1) {
          this.loader.done();
          this.router.navigate(['/properties']);
        }
      })
  }

}
