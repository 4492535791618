import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {appConfig} from '../app.config';
import * as jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {
  admins: any[] = [];
  private adminsUpdated = new Subject<any[]>();
  user: any[] = [];
  private userUpdated = new Subject<any[]>();

  prices: any[] = [];
  private updatedPrices = new Subject<any[]>();

blogs: any[] = [];
private blogsUpdated = new Subject<any[]>();

  property: any[] = [];
  private propertyUpdated = new Subject<any[]>();

  buyer: any[] = [];
  private buyerUpdated = new Subject<any[]>();

  seller: any[] = [];
  private sellerUpdated = new Subject<any[]>();
  constructor(private http: HttpClient) { }

  getAdmins() {
    this.http
      .get<any>(appConfig.apiUrl + '/api/getAdmins')
      .pipe(
        map(adminData => {
          return adminData.data.map(admin => {
            console.log(admin);
            return admin;
          });
        })
      )
      .subscribe(admin => {
        console.log(admin);
        this.admins = admin;
        console.log(this.admins);
        this.adminsUpdated.next([...this.admins]);
      });
  }

  getAdminUpdateListen() {
    return this.adminsUpdated.asObservable();
  }


  registerAdmin(data) {
    return this.http.post<any>(`${appConfig.apiUrl}/api/adminregister`, data);
  }

  blockAdmin(id) {
    const data = {
      id
    }
    return this.http.post<any>(`${appConfig.apiUrl}/api/adminBlock`, data);
  }

  unblockAdmin(id) {
    const data = {
      id
    }
    return this.http.post<any>(`${appConfig.apiUrl}/api/adminUnBlock`, data);
  }

  getAdmin(id) {
    return this.http.get<any>(`${appConfig.apiUrl}/api/singleAdmin/${id}`)
  }

  editAdmin(data) {
    return this.http.post<any>(`${appConfig.apiUrl}/api/adminEdit`, data)
  }

  login(data) {
    return this.http.post<any>(`${appConfig.apiUrl}/api/adminLogin`, data);
  }

  getUser() {
    const token = jwt_decode(localStorage.getItem('tokenCheck')).user;
    console.log(token, 'this is toekn')
    console.log(token, 'this is toekn')
    this.http.get<any>(`${appConfig.apiUrl}/api/singleAdmin/${token._id}`)
    .pipe(
      map(adminData => {
        console.log(adminData, 'dsfaj;skl;')
        return adminData

      })
    )
    .subscribe(admin => {
      console.log(admin);
      this.user = admin;
      console.log(this.user);
      this.userUpdated.next({...this.user});
    });
  }

  getUserUpdated() {
    return this.userUpdated.asObservable()
  }

  createBlog(data) {
    return this.http.post<any>(`${appConfig.apiUrl}/api/addBlog`, data)
  }

  getBlogs() {
    this.http.get<any>(`${appConfig.apiUrl}/api/blogs`)
    .pipe(
      map(blogData => {
        return blogData.data
      })
    )
    .subscribe(blogs => {
      this.blogs = blogs;
      this.blogsUpdated.next([...this.blogs]);
    })
  }

  getUpdatedBlogs() {
    return this.blogsUpdated.asObservable();
  }


deleteBlog(id) {
  const data = {
    id
  }
  return this.http.post(`${appConfig.apiUrl}/api/deleteBlog`, data)
}

getPorperty () {
  this.http.get<any>(`${appConfig.apiUrl}/api/properties`)
  .pipe(
    map(propertyData => {
      return propertyData.data.map(property => {
        return property;
      });

    })
  )
  .subscribe(property => {
    this.property = property;
    this.propertyUpdated.next([...this.property]);
  });
}

getUpdatedProperty() {
  return this.propertyUpdated.asObservable();
}

makeActive(id) {
  const data = {
    id
  };
  return this.http.post<any>(`${appConfig.apiUrl}/api/activePropertyStatus`, data);
}

makeInactive(id) {
  const data = {
    id
  };
  return this.http.post<any>(`${appConfig.apiUrl}/api/rejectedPropertyStatus`, data);
}

getSellers() {
  this.http.get<any>(`${appConfig.apiUrl}/api/getSellers`)
  .pipe(
    map(sellerData => {
      return sellerData.data.map(sellers => {
        return sellers;
      });

    })
  )
  .subscribe(sellers => {
    this.seller = sellers;
    this.sellerUpdated.next([...this.seller]);
  });
}

getSellersUpdated() {
  return this.sellerUpdated.asObservable();
}

makeSellerActive(id) {
  const data = {
    id
  };
  return this.http.post<any>(`${appConfig.apiUrl}/api/sellerUnblock`, data);
}

makeSellerInactive(id) {
  const data = {
    id
  };
  return this.http.post<any>(`${appConfig.apiUrl}/api/sellerBlock`, data);
}

zooplaUpload(id) {
    const data = {
      id
    }
    return this.http.post<any>(`${appConfig.apiUrl}/api/uploadZoopla`, data);
}

singleProperty(id) {
return this.http.get<any>(`${appConfig.apiUrl}/api/singleProperty/${id}`);
}

getPropertyDetail(id) {
  const data = {
    id
  }
  return this.http.post<any>(`${appConfig.apiUrl}/api/propertyDetail`, data)
}
singleUser(id) {
  return this.http.get<any>(`${appConfig.apiUrl}/api/singleSeller/${id}`)
}

sendMail(data) {
  return this.http.post<any>(`${appConfig.apiUrl}/api/rejectProperty`, data);
}

blockSellerMail(email) {
  const data = {
    email
  }
  this.http.post<any>(`${appConfig.apiUrl}/api/sellerBlockMail`, data)
  .subscribe(data => {
    console.log(data, 'block seller mail')
  })
}

singleBlog(id) {
  return this.http.get<any>(`${appConfig.apiUrl}/api/getBlog/${id}`)
}

getPrices() {
  this.http.get<any>(`${appConfig.apiUrl}/api/prices`)
  .pipe(
    map(priceData => {
      return priceData.data.map(prices => {
        return prices
      })
    })
  )
  .subscribe(prices => {
    this.prices = prices;
    this.updatedPrices.next([...this.prices])
  })
}

getUpdatedPrices () {
  return this.updatedPrices.asObservable();
}

deletePrice(id) {
  return this.http.post<any>(`${appConfig.apiUrl}/api/deletePrice`, {id});
}

addPrice(data) {
  return this.http.post<any>(`${appConfig.apiUrl}/api/addPrice`, data)
}
}
