import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { EditPropertyComponent } from './components/edit-property/edit-property.component';
import { SellersComponent } from './components/sellers/sellers.component';
import { BuyersComponent } from './components/buyers/buyers.component';
import { AdminsComponent } from './components/admins/admins.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { AllBlogsComponent } from './components/all-blogs/all-blogs.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RejectComponent } from './components/reject/reject.component';
import { NgProgressModule } from 'ngx-progressbar';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { PropertyDetailComponent } from './components/property-detail/property-detail.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PricingComponent } from './components/pricing/pricing.component';
// import { CKEditorModule } from '@ckeditor/ckeditor5-build-classic';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PropertiesComponent,
    EditPropertyComponent,
    SellersComponent,
    BuyersComponent,
    AdminsComponent,
    BlogsComponent,
    RegisterComponent,
    LoginComponent,
    EditUserComponent,
    AllBlogsComponent,
    RejectComponent,
    PropertyDetailComponent,
    PricingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    NgProgressModule,
    FlashMessagesModule.forRoot(),
    CKEditorModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
