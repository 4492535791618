import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminServiceService } from '../../services/admin-service.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  pricing: FormGroup;
  prices: any= []
  constructor(
    private adminService: AdminServiceService
  ) { }

  ngOnInit() {
    this.pricing = new FormGroup({
      price: new FormControl(null)
    });

    this.adminService.getPrices();
    this.adminService.getUpdatedPrices()
    .subscribe(data => {
      console.log(data, 'this is prices')
      this.prices = data;
    })
  }

  addPrice() {
    this.adminService.addPrice(this.pricing.value)
    .subscribe(data => {
      console.log(data);
      this.adminService.getPrices();
      this.pricing.reset();
    })
  }

  deletePrice(id) {
    this.adminService.deletePrice(id)
    .subscribe(data => {
      console.log(data)
      this.adminService.getPrices()
    })
  }

}
