import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogsComponent } from './components/blogs/blogs.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { EditPropertyComponent } from './components/edit-property/edit-property.component';
import { SellersComponent } from './components/sellers/sellers.component';
import { BuyersComponent } from './components/buyers/buyers.component';
import { AdminsComponent } from './components/admins/admins.component';
import { RegisterComponent } from './components/register/register.component';
import { EditUserComponent } from './components/edit-user/edit-user.component'
import { LoginComponent } from './components/login/login.component';
import { AllBlogsComponent } from './components/all-blogs/all-blogs.component';
import { RejectComponent } from './components/reject/reject.component';
import { PropertyDetailComponent } from './components/property-detail/property-detail.component';
import { PricingComponent } from './components/pricing/pricing.component';
const routes: Routes = [
  {
    path: 'properties',
    component: PropertiesComponent
  },
  {
    path: 'editProperty/:id',
    component: EditPropertyComponent
  },
  {
    path: 'admins',
    component: AdminsComponent
  },
  {
    path: 'sellers',
    component: SellersComponent
  },
  {
    path: 'buyers',
    component: BuyersComponent
  },
  {
    path: 'blogs',
    component: BlogsComponent
  },
  {
    path: 'admin/register',
    component: RegisterComponent
  },
  {
    path: 'edit/:id',
    component: EditUserComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'allblogs',
    component: AllBlogsComponent
  },
  {
    path: 'reject/:id',
    component: RejectComponent
  },
  {
    path: 'property/:id',
    component: PropertyDetailComponent
  },
  {
    path: 'blog/:id',
    component: BlogsComponent
  },
  {
    path: 'pricing',
    component: PricingComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
